table {
  border-collapse: collapse;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  text-align: center;
}

.table-vertical-bordered td:not(:last-child),
.table-vertical-bordered th:not(:last-child) {
  border-right: 1px solid var(--color-primary);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--color-primary-step-900);
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: center;
}

th {
  text-align: inherit
}

.table-fixed {
  table-layout: fixed;
}
