.logo {
  height: 8rem !important;
  width: 12rem !important;
}

.form-el {
  align-items: center;
}

.label {
  color: var(--color-primary) !important;
  text-align: left;
}

.input {
  border-radius: 6px;
  border: 2px solid var(--color-primary);
}
