:root {
  /*old color scheme*/
  /*--color-primary: #2699fb;*/
  /*--color-primary-contrast: white;*/
  /*--color-primary-tint: #4c8dff;*/
  /*--color-primary-step-700: #b3d9f9;*/
  /*--color-primary-step-900: #e9f5ff;*/

  --color-primary: #81c241;
  --color-primary-contrast: white;
  --color-primary-tint: #6ba53a;
  --color-primary-step-700: rgba(129, 194, 65, 0.71);
  --color-primary-step-900: rgba(129, 194, 65, 0.2);

  --color-secondary: #7f7f7f;
  --color-black: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  overflow: hidden;
}

a:hover {
  color: var(--color-primary-tint);
}

.primary-text {
  color: var(--color-primary) !important;
}

.secondary-text {
  color: var(--color-secondary);
}

.button {
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, .6);
  margin: 0 .25em;
  padding: .78571429em 1.5em .78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  border-radius: .28571429rem;
}

.primary.button,
.ui.primary.button {
  color: var(--color-primary-contrast);
  background-color: var(--color-primary) !important;
}

.primary.button:hover,
.ui.primary.button:hover {
  color: var(--color-primary-contrast);
  background-color: var(--color-primary-tint);
}

/* Semantic UI */
div.ui.input.primary>input,
input.primary {
  color: var(--color-primary-tint);
}

.ui.grid>div.row {
  padding: 0.5rem 0;
}

.ui.disabled.input,
.ui.form .field :disabled {
  opacity: 0.8;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, .5);
}

.dashboard {
  height: 100%;
  overflow: hidden;
}

.eco-pagination-container {
  padding: 0 0 60px 0;
}
.eco-pagination-container ._page_cont, ._page_item {
  float: right;
}
.eco-pagination-container ._page_cont, ._page_item button {
  border-radius: 0.2rem;
}
.element-disabled {
  pointer-events: none;
  opacity: 0.5;
}
