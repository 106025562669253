.margin-l {
  margin-left: 1rem;
}

.margin-r {
  margin-right: 1rem;
}

.page-padding {
  padding: 9rem 2rem 1rem;
}

.page-padding-small {
  padding: 1rem 2rem;
}

.full-w {
  width: 100%;
}

.relative {
  position: relative;
}

.clear {
  background: transparent !important;
}

.content-scrollable {
  overflow: auto;
  height: 100%;
}

.focus-none {
  outline: none;
}
