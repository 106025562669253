.App {
	display: inline-flex;
	height: 100%;
	width: 100%;
}

.side-menu {
	width: 260px;
	background-color: var(--color-primary-step-900);
  font-weight: 600;
}

.main {
	width: 100%;
}

.side-container {
  margin: 0 2rem;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

:local(.side-container)::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.menuBtn {
  position: absolute;
  margin: 1.5rem 0.5rem 0.5rem !important;
  z-index: 2;
}

.menu-header {
	display: flex;
	justify-content: space-between;
	margin: 4.5rem 0 1.5rem;
}

/*IDE warning, check if the items below are still relevant */
/*:local(.menu-header > *) {*/
/*  margin-right: 1rem;*/
/*}*/

.menu-content {
	display: flex;
	flex-direction: column;
  margin-top: 1rem;
  height: 71%;
}

nav > a {
	margin: 0.5rem 0;
	color: var(--color-secondary);
}

:local(.search):global(.ui.input) > input {
  border-color: var(--color-primary);
}

:local(.search) > i {
  color: var(--color-primary);
}

.active {
  font-weight: 800;
  text-decoration: underline;
}
