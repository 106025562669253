.input {
  width: 70px;
}

:local(.input ) :global(.ui.icon.button) {
  color: var(--color-primary-contrast);
  background-color: var(--color-secondary)
}

.active {
  width: 70px;
}

:local(.active ) :global(.ui.icon.button) {
  color: var(--color-primary-contrast);
  background-color: var(--color-primary)
}
